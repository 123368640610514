import { Container, Modal, Spinner } from "react-bootstrap";
import { Student } from "../../models/student";
import { UseSetComplete } from "../../services/Query";
interface Props {
  show: boolean;
  onOk: () => void;
  user: Student | undefined;
}

export const TaskCompleteModal = (props: Props) => {
  const { onOk, show, user } = props;
  const { mutate: manualComplete, isLoading: isManualCompleting } =
    UseSetComplete();
  const onConfirm = () => {
    if (user) {
      manualComplete(JSON.stringify({ id: user.id }), {
        onSuccess: () => {
          onOk();
        },
      });
    }
  };
  return (
    <Modal
      show={show}
      onHide={onOk}
      className="dashboard_video"
      centered={true}
    >
      <Modal.Header closeButton closeVariant="white"></Modal.Header>
      <Modal.Body className="d-flex justify-content-center">
        <Container>
          <p className="text-center">
            Are you sure you want to move {user?.firstname} {user?.lastName} to
            "Completed" tab
          </p>
          <div className="d-flex justify-content-center pt-3">
            <button
              className="btn btn-success py-1 px-4 fs-5 ms-3 fw-bold"
              onClick={() => {
                onConfirm();
              }}
            >
              {isManualCompleting && (
                <Spinner animation="border" className="me-1" size="sm" />
              )}
              Yes
            </button>
            <button
              className="btn btn-secondary py-1 px-4 fs-5 ms-3 fw-bold"
              onClick={() => {
                onOk();
              }}
            >
              No
            </button>
          </div>
        </Container>
      </Modal.Body>
    </Modal>
  );
};
