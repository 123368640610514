import { useMutation, useQuery } from "react-query";
import {
  Activity,
  ExcelODto,
  ILogSummary,
  IReportType,
  IStudentInfos,
  LogPage,
  LoginRes,
  LoginUser,
  Member,
  SearchWeight,
  SummaryDto,
} from "../models";
import HttpService from "./HttpServices";
import { Admin } from "../models/admin";
import { Student } from "../models/student";
import { Parent } from "../models/parent";
import { StateGrants } from "../models/stategrant";
import { School } from "../models/school";

export const useSaveSearchWeight = () => {
  return useMutation<any, Error, string>(
    "save_search_weight",
    async (formdata) => {
      return await HttpService.saveSearchWeight(formdata);
    },
    {
      retry: false,
    }
  );
};
export const useAdminSignin = () => {
  return useMutation<LoginUser, Error, string>(
    ["login_admin"],
    async (formdata) => {
      return await HttpService.loginAdmin(formdata);
    },
    {
      retry: false,
    }
  );
};
export const useSaveUser = () => {
  return useMutation<any, Error, string>(
    ["save_user"],
    async (formdata) => {
      return await HttpService.RegUser(formdata);
    },
    {
      retry: false,
    }
  );
};
export const useGetUsers = () => {
  return useQuery<Member[] | undefined, Error>(
    ["get_all_users"],
    async () => {
      return await HttpService.getUserList();
    },
    {
      retry: false,
    }
  );
};
export const useSummaryData = (startData: string, endDate: string) => {
  return useQuery<SummaryDto, Error>(
    ["get_summary_data", startData, endDate],
    async () => {
      return await HttpService.getSummaryData(startData, endDate);
    },
    {
      retry: false,
    }
  );
};
export const useSummaryLogs = (startData: string, endDate: string) => {
  return useQuery<ILogSummary, Error>(
    ["get_summary_login", startData, endDate],
    async () => {
      return await HttpService.getLogSummary(startData, endDate);
    },
    {
      retry: false,
    }
  );
};
export const useSummaryLetterLogs = (startData: string, endDate: string) => {
  return useQuery<ILogSummary, Error>(
    ["get_summary_letters", startData, endDate],
    async () => {
      return await HttpService.getLogLessonsSummary(startData, endDate);
    },
    {
      retry: false,
    }
  );
};
export const useAdminList = () => {
  return useQuery<Admin[], Error>(
    ["get_admin_list"],
    async () => {
      return await HttpService.getAdminList();
    },
    {
      retry: false,
    }
  );
};
export const useReportUsers = () => {
  return useQuery<IReportType, Error>(
    ["get_report_users"],
    async () => {
      return await HttpService.getReportUsers();
    },
    {
      retry: false,
    }
  );
};
export const useSpringReportUsers = () => {
  return useQuery<IReportType, Error>(
    ["get_spring_report_users"],
    async () => {
      return await HttpService.getSpringReportUsers();
    },
    {
      retry: false,
    }
  );
};
export const useStudentList = () => {
  return useQuery<IStudentInfos, Error>(
    ["get_student_list"],
    async () => {
      return await HttpService.getStudentList();
    },
    {
      retry: false,
    }
  );
};
export const useParentList = () => {
  return useQuery<Parent[], Error>(
    ["get_parent_list"],
    async () => {
      return await HttpService.getParentList();
    },
    {
      retry: false,
    }
  );
};
export const useSummaryLessonsAll = (startData: string, endDate: string) => {
  return useQuery<ILogSummary, Error>(
    ["get_summary_lessons_all", startData, endDate],
    async () => {
      return await HttpService.getLogLessonsAllData(startData, endDate);
    },
    {
      retry: false,
    }
  );
};
export const useSummaryActivitiesLogs = (
  startData: string,
  endDate: string
) => {
  return useQuery<ILogSummary, Error>(
    ["get_summary_activities", startData, endDate],
    async () => {
      return await HttpService.getLogActivitiesSummary(startData, endDate);
    },
    {
      retry: false,
    }
  );
};
export const useSummaryActivitiesAll = (startData: string, endDate: string) => {
  return useQuery<ILogSummary, Error>(
    ["get_summary_activities_all", startData, endDate],
    async () => {
      return await HttpService.getLogActivitiesAllData(startData, endDate);
    },
    {
      retry: false,
    }
  );
};
export const useGetActivities = () => {
  return useQuery<Activity[] | undefined, Error>(
    ["get_all_activities"],
    async () => {
      return await HttpService.getActivityData();
    },
    {
      retry: false,
    }
  );
};
export const useStateGrants = () => {
  return useQuery<StateGrants[] | undefined, Error>(
    ["get_state_grants"],
    async () => {
      return await HttpService.getStateGrantList();
    },
    {
      retry: false,
    }
  );
};
export const useSchoolList = () => {
  return useQuery<School[] | undefined, Error>(
    ["get_school_list"],
    async () => {
      return await HttpService.getSchoolList();
    },
    {
      retry: false,
    }
  );
};
export const useGetLogList = (page: number) => {
  return useQuery<LogPage | undefined, Error>(
    ["get_log_list", page],
    async () => {
      return await HttpService.getLogList(page);
    },
    {
      retry: false,
    }
  );
};
export const useSearchWeight = () => {
  return useQuery<SearchWeight | undefined, Error>(
    ["get_search_weight"],
    async () => {
      return await HttpService.getSearchWeight();
    },
    {
      retry: false,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );
};
export const UseInsertUsersExcel = () => {
  return useMutation<string[], Error, string>(
    ["insert_user_excel"],
    async (frm: string) => {
      const res = await HttpService.postInsertDataExcel(frm);
      return res;
    },
    {
      retry: false,
    }
  );
};
export const UseSaveStudentInfo = () => {
  return useMutation<any, Error, string>(
    ["save_user_student"],
    async (frm: string) => {
      const res = await HttpService.saveStudentData(frm);
      return res;
    },
    {
      retry: false,
    }
  );
};
export const UseSaveAdminData = () => {
  return useMutation<any, Error, string>(
    ["save_admin_data"],
    async (frm: string) => {
      const res = await HttpService.RegAdmin(frm);
      return res;
    },
    {
      retry: false,
    }
  );
};
export const UseSaveStateGrant = () => {
  return useMutation<any, Error, string>(
    ["save_state_grant"],
    async (frm: string) => {
      const res = await HttpService.saveStateGrant(frm);
      return res;
    },
    {
      retry: false,
    }
  );
};
export const UseSaveSchoolData = () => {
  return useMutation<any, Error, string>(
    ["save_school_data"],
    async (frm: string) => {
      const res = await HttpService.saveSchoolData(frm);
      return res;
    },
    {
      retry: false,
    }
  );
};
export const UseGetDecodedSSN = () => {
  return useMutation<any, Error, string>(
    ["get_decoded_ssn"],
    async (frm: string) => {
      const res = await HttpService.getDecodedSSn(frm);
      return res;
    },
    {
      retry: false,
    }
  );
};
export const UseGenerateLetterMail = () => {
  return useMutation<any, Error, string>(
    ["generate_letter_mail"],
    async (id: string) => {
      const res = await HttpService.generateLetterMail(id);
      return res;
    },
    {
      retry: false,
    }
  );
};
export const UseResetCurStudent = () => {
  return useMutation<any, Error, string>(
    ["reset_cur_student"],
    async (email: string) => {
      const res = await HttpService.resetCurStudent(email);
      return res;
    },
    {
      retry: false,
    }
  );
};
export const UseResendConfirmPassword = () => {
  return useMutation<any, Error, string>(
    ["resend_confirm_password"],
    async (email: string) => {
      const res = await HttpService.resendConfirmPassword(email);
      return res;
    },
    {
      retry: false,
    }
  );
};

export const UseManualComplete = () => {
  return useMutation<any, Error, string>(
    ["manual_complete"],
    async (params: string) => {
      const res = await HttpService.manualComplete(params);
      return res;
    },
    {
      retry: false,
    }
  );
};
export const UseSetComplete = () => {
  return useMutation<any, Error, string>(
    ["set_completed"],
    async (params: string) => {
      const res = await HttpService.SetCompleted(params);
      return res;
    },
    {
      retry: false,
    }
  );
};
