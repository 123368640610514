import axios from "axios";
import {
  Activity,
  ILogSummary,
  IReportType,
  IStudentInfos,
  LoginRes,
  LoginUser,
  LogPage,
  Member,
  SearchWeight,
  SummaryDto,
} from "../models";
import TokenServices from "./TokenServices";
import { Admin } from "../models/admin";
import { Student } from "../models/student";
import { Parent } from "../models/parent";
import { StateGrants } from "../models/stategrant";
import { School } from "../models/school";
const backend_baseUrl = process.env.REACT_APP_BACKEND_URL;
const apiClient = axios.create({
  baseURL: `${backend_baseUrl}/api`,
  headers: {
    "Access-Control-Allow-Origin": "*",
    Accepts: "application/json",
  },
  withCredentials: false,
});
apiClient.interceptors.request.use(async (config) => {
  const token = TokenServices.getLocalAccessToken();
  let sToken = "";
  try {
    if (token) sToken = JSON.parse(token);
  } catch (er) {
    sToken = token ?? "";
  }
  if (config && config.headers && !config.headers["Authorization"]) {
    config.headers["Authorization"] = `Bearer ${sToken}`;
  }
  if (config.headers) config.headers["Content-Type"] = "application/json";
  return config;
});

const loginAdmin = async (formData: string) => {
  const response = await apiClient.post<LoginUser>(
    "Asset/login_admin",
    formData
  );
  return response.data;
};
const getUserList = async () => {
  const response = await apiClient.get<Member[]>("Asset/UserList");
  return response.data;
};
const toggleUserType = async (memberId: string) => {
  const response = await apiClient.get(`Login/toggleMemberType/${memberId}`);
  return response.data;
};
const toggleUserValid = async (memberId: string) => {
  const response = await apiClient.get(`Login/toggleMemberValid/${memberId}`);
  return response.data;
};
const getLogList = async (page: number) => {
  const response = await apiClient.get<LogPage>(`Asset/LogList/${page}`);
  return response.data;
};
const getActivityData = async () => {
  var response = await apiClient.get<Activity[]>("Asset/ActivityList");
  return response.data;
};
const getSearchWeight = async () => {
  var response = await apiClient.get<SearchWeight>("Asset/SearchWeight");
  return response.data;
};
const saveSearchWeight = async (formData: string) => {
  const response = await apiClient.post<any>(
    "Asset/SaveSearchWeight",
    formData
  );
  return response.data;
};
const RegUser = async (formData: string) => {
  const response = await apiClient.post<any>("Asset/reg_user", formData);
  return response.data;
};
const RegAdmin = async (formData: string) => {
  const response = await apiClient.post<any>("Asset/save_admin_user", formData);
  return response.data;
};
const saveStateGrant = async (formData: string) => {
  const response = await apiClient.post<any>(
    "Asset/save_state_grant",
    formData
  );
  return response.data;
};
const saveSchoolData = async (formData: string) => {
  const response = await apiClient.post<any>(
    "Asset/save_school_info",
    formData
  );
  return response.data;
};
const postInsertDataExcel = async (formdata: string) => {
  const response = await apiClient.post<string[]>(
    "Asset/insert_user_excel",
    formdata
  );
  return response.data;
};
const getSummaryData = async (startData: string, endDate: string) => {
  const response = await apiClient.get<SummaryDto>(
    `Asset/summary_data/${startData}/${endDate}`
  );
  return response.data;
};
const getLogSummary = async (sdate: string, edate: string) => {
  const response = await apiClient.get<ILogSummary>(
    `Asset/report_login/${sdate}/${edate}`
  );
  return response.data;
};
const getLogLetterSummary = async (sdate: string, edate: string) => {
  const response = await apiClient.get<ILogSummary>(
    `Asset/report_letters/${sdate}/${edate}`
  );
  return response.data;
};
const getLogLessonsAllData = async (sdate: string, edate: string) => {
  const response = await apiClient.get<ILogSummary>(
    `Asset/report_lessons_all/${sdate}/${edate}`
  );
  return response.data;
};
const getLogActivitiesAllData = async (sdate: string, edate: string) => {
  const response = await apiClient.get<ILogSummary>(
    `Asset/report_activities_all/${sdate}/${edate}`
  );
  return response.data;
};
const getLogActivitiesSummary = async (sdate: string, edate: string) => {
  const response = await apiClient.get<ILogSummary>(
    `Asset/report_activities/${sdate}/${edate}`
  );
  return response.data;
};
const getAdminList = async () => {
  var response = await apiClient.get<Admin[]>("Asset/admin_list");
  return response.data;
};
const getStudentList = async () => {
  var response = await apiClient.get<IStudentInfos>(
    "Asset/student_list_with_financial"
  );
  return response.data;
};
const saveStudentData = async (formData: string) => {
  var response = await apiClient.post<any>("Login/save_student", formData);
  return response.data;
};
const getParentList = async () => {
  var response = await apiClient.get<Parent[]>("Asset/parent_list");
  return response.data;
};
const getStateGrantList = async () => {
  var response = await apiClient.get<StateGrants[]>("Asset/state_grant_list");
  return response.data;
};
const getSchoolList = async () => {
  var response = await apiClient.get<School[]>("Asset/get_school_list");
  return response.data;
};
const getDecodedSSn = async (params: string) => {
  var response = await apiClient.get<string>(`Asset/get_decoded_ssn/${params}`);
  return response.data;
};
const getReportUsers = async () => {
  var response = await apiClient.get<IReportType>(`Asset/report_users`);
  return response.data as IReportType;
};
const getSpringReportUsers = async () => {
  var response = await apiClient.get<IReportType>(`Asset/spring_report_users`);
  return response.data as IReportType;
};
const generateLetterMail = async (id: string) => {
  const response = await apiClient.get<any>(
    `Financial/generate_letter_email/${id}`
  );
  return response.data;
};
export const resetCurStudent = async (email: string) => {
  const response = await apiClient.get(`Login/reset_cur_user/${email}`);
  return response.data;
};
export const resendConfirmPassword = async (email: string) => {
  const response = await apiClient.get(
    `Login/resend_confirm_password/${email}`
  );
  return response.data;
};
export const manualComplete = async (params: string) => {
  const response = await apiClient.post(
    `Student/manual_complete_student`,
    params
  );
  return response.data;
};
export const SetCompleted = async (params: string) => {
  const response = await apiClient.post(
    `Student/set_completed_student`,
    params
  );
  return response.data;
};
const HttpService = {
  loginAdmin,
  getUserList,
  toggleUserType,
  toggleUserValid,
  getLogList,
  getActivityData,
  getSearchWeight,
  saveSearchWeight,
  RegUser,
  postInsertDataExcel,
  getSummaryData,
  getLogSummary,
  getLogLessonsSummary: getLogLetterSummary,
  getLogActivitiesSummary,
  getLogLessonsAllData,
  getLogActivitiesAllData,
  RegAdmin,
  getAdminList,
  getStudentList,
  getParentList,
  getStateGrantList,
  saveStateGrant,
  getSchoolList,
  saveSchoolData,
  saveStudentData,
  getDecodedSSn,
  getReportUsers,
  getSpringReportUsers,
  generateLetterMail,
  resetCurStudent,
  resendConfirmPassword,
  manualComplete,
  SetCompleted,
};
export default HttpService;
