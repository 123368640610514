import React, { useEffect, useState } from 'react';
import { Container, Form, Modal, Spinner } from 'react-bootstrap';
import { Audio } from 'react-loader-spinner';
import { Student } from '../../models/student';
interface Props {
  show: boolean;
  isSending: boolean;
  onOk: () => void;
  user: Student | undefined;
}

export const LoadingBox = () => {
  return (
    <div className="d-flex justify-content-center mb-2">
      <Audio
        height="100"
        width="100"
        color="#4fa94d"
        ariaLabel="audio-loading"
        wrapperStyle={{}}
        wrapperClass="wrapper-class"
        visible={true}
      />
    </div>
  );
};

export const ResendConfirmModal = (props: Props) => {
  const { isSending, onOk, show, user } = props;
  return (
    <Modal
      show={props.show}
      onHide={props.onOk}
      className="dashboard_video"
      centered={true}
    >
      <Modal.Header closeButton closeVariant="white">
        {/* <Modal.Title className="fs-5">
        
        </Modal.Title> */}
      </Modal.Header>
        <Modal.Body className="d-flex justify-content-center">
          <Container>
            {isSending && (
              <LoadingBox/>
            )}
            {!isSending && (
              <>
                <p className="fw-semibold text-center">
                  We have sent a confirmation email to the user below successfullly! 
                </p>
                <p>
                  <span className="fw-semibold">User Name:</span> {user?.firstname || ""} {user?.lastName || ""}
                </p>
                <p>
                  <span className="fw-semibold">User Email:</span> {user?.email || ""}
                </p>
                <div className="d-flex justify-content-center pt-3">
                  <button
                    className="btn btn-secondary py-1 px-4 fs-5 ms-3 fw-bold"
                    onClick={() => {
                      onOk();
                    }}
                  >
                    Ok
                  </button>
                </div>
              </>
            )}
          </Container>
        </Modal.Body>
      
    </Modal>
  );
};
