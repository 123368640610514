import moment from "moment";
import { Audio } from "react-loader-spinner";
import {
  useReportUsers,
  UseResetCurStudent,
  UseResendConfirmPassword,
} from "../services/Query";
import { Button, Form, Spinner, Tab, Tabs } from "react-bootstrap";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { CSVLink } from "react-csv";
import { RegenerateLetterConfirm } from "../components/RegenerateLetterConfirm";
import { useMemo, useRef, useState } from "react";
import { FinancialAid } from "../models";
import { Student } from "../models/student";
import { ResendConfirmModal } from "../components/modal/ResendConfirmModal";
import { ResetPasswordModal } from "../components/modal/ResetPasswordModal";
import { ManualCompleteModal } from "../components/modal/ManualCompleteModal";
import { TaskCompleteModal } from "../components/modal/TaskCompleteModal";

const LoadingBox = () => {
  return (
    <div className="d-flex justify-content-center mb-2">
      <Audio
        height="100"
        width="100"
        color="#4fa94d"
        ariaLabel="audio-loading"
        wrapperStyle={{}}
        wrapperClass="wrapper-class"
        visible={true}
      />
    </div>
  );
};

export const Reports = () => {
  const {
    data: reports,
    isLoading,
    isRefetching,
    refetch: reloadReports,
  } = useReportUsers();
  const activatedRef = useRef<HTMLTableElement>(null);
  const submittedRef = useRef<HTMLTableElement>(null);
  const [activePage, setActivePage] = useState(1);
  const [noActivePage, setnoActivePage] = useState(1);
  const [submittedPage, setSubmittedPage] = useState(1);
  const [curUserId, setCurUserId] = useState<string | undefined>(undefined);
  const [curStudent, setCurStudent] = useState<Student | undefined>(undefined);
  const { mutate: sendResetEmail, isLoading: isSendingResetEmail } =
    UseResetCurStudent();
  const { mutate: sendConfirmEmail, isLoading: isSendingConfirmEmail } =
    UseResendConfirmPassword();
  const [curFinancialAid, setCurFinancialAid] = useState<
    FinancialAid | undefined
  >(undefined);
  const [showLetterModal, setShowLetterModal] = useState(false);
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [showResendConfirmModal, setShowResendConfirmModal] = useState(false);
  const [showManualCompleteModal, setShowManualCompleteModal] = useState(false);
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const rows = 20;
  const gpa_list = [
    { label: "GPA 4.00 or Higher", value: "25000" },
    { label: "GPA 3.99-3.80", value: "25000" },
    { label: "GPA 3.50-3.79", value: "24000" },
    { label: "GPA 3.25-3.49", value: "23000" },
    { label: "GPA 3.00-3.24", value: "22000" },
    { label: "GPA 2.75-2.99", value: "22000" },
    { label: "GPA 2.50-2.74", value: "17000" },
    { label: "GPA 2.25-2.49", value: "17000" },
    { label: "GPA 2.00-2.24", value: "17000" },
    { label: "GPA Below 2.00", value: "0" },
  ];

  const satList = [
    "",
    "800-890",
    "900-990",
    "1000-1090",
    "1100-1190",
    "1200-1290",
    "1300-1390",
    "1400-1490",
    "1500-1590",
    "1600",
    "Not taken",
  ];

  const noActivateTotalPage = useMemo(() => {
    if (reports?.noActivatedUsers.length) {
      return Math.ceil(reports.noActivatedUsers.length / rows);
    }
    return 0;
  }, [reports]);
  const getParent = (sId: string) => {
    if (reports?.parents) {
      return reports.parents.find((it) => it.createdByStudentId == sId);
    }
    return undefined;
  };
  const getFinancialObj = (sId: string) => {
    if (reports?.financialAids) {
      return reports.financialAids.find((it) => it._Student.id == sId);
    }
    return undefined;
  };
  const noactivateRows = useMemo(() => {
    if (reports?.noActivatedUsers.length) {
      const sPos = (noActivePage - 1) * rows;
      return reports.noActivatedUsers.slice(sPos, sPos + rows);
    }
    return [];
  }, [reports, noActivePage]);
  const submittedTotalPage = useMemo(() => {
    if (reports?.submittedUsers.length) {
      return Math.ceil(
        reports.submittedUsers.filter(
          (it) => moment(it.letter_send_date).toDate().getFullYear() >= 2024
        ).length / rows
      );
    }
    return 0;
  }, [reports]);
  const submittedRows = useMemo(() => {
    if (reports?.submittedUsers.length) {
      const sPos = (submittedPage - 1) * rows;
      return reports.submittedUsers
        .filter(
          (it) => moment(it.letter_send_date).toDate().getFullYear() >= 2024
        )
        .slice(sPos, sPos + rows);
    }
    return [];
  }, [reports, submittedPage]);
  const manualCompletedUsers = useMemo(() => {
    if (reports?.submittedUsers.length) {
      return reports.manual_complete_students;
    }
    return [];
  }, [reports]);
  const activateTotalPage = useMemo(() => {
    if (reports?.activatedUsers.length) {
      return Math.ceil(reports.activatedUsers.length / rows);
    }
    return 0;
  }, [reports]);
  const activateRows = useMemo(() => {
    if (reports?.activatedUsers.length) {
      const sPos = (activePage - 1) * rows;

      let simplearray = reports?.submittedUsers
        .filter(
          (it) => moment(it.letter_send_date).toDate().getFullYear() >= 2024
        )
        .map((itemY) => {
          return itemY._Student.email;
        });

      return reports.activatedUsers
        .filter((itemX) => !simplearray.includes(itemX.email))
        .slice(sPos, sPos + rows);
    }
    return [];
  }, [reports, activePage]);
  const totalCountActivatedUsers = useMemo(() => {
    if (reports?.activatedUsers.length) {
      let simplearray = reports?.submittedUsers
        .filter(
          (it) => moment(it.letter_send_date).toDate().getFullYear() >= 2024
        )
        .map((itemY) => {
          return itemY._Student.email;
        });
      return reports?.activatedUsers.filter(
        (itemX) => !simplearray.includes(itemX.email)
      ).length;
    }
    return [];
  }, [reports, activePage]);
  const excelActivateData = useMemo(() => {
    if (reports?.activatedUsers) {
      let simplearray = reports?.submittedUsers
        .filter(
          (it) => moment(it.letter_send_date).toDate().getFullYear() >= 2024
        )
        .map((itemY) => {
          return itemY._Student.email;
        });

      return reports?.activatedUsers
        .filter((itemX) => !simplearray.includes(itemX.email))
        .map((_it) => {
          const parentObj = getParent(_it.id);
          return {
            firstname: _it.firstname,
            lastname: _it.lastName,
            email: _it.email,
            parent_firstName: parentObj?.firstName || "",
            parent_lastName: parentObj?.lastName || "",
            parent_email: parentObj?.email || "",
            createdDate: moment(_it.createdDate).format("l"),
          };
        });
    }
    return [];
  }, [reports]);
  const manualCompletedData = useMemo(() => {
    if (reports?.manual_complete_students) {
      return reports.manual_complete_students.map((_it) => {
        return {
          firstname: _it.firstname,
          lastname: _it.lastName,
          email: _it.email,
          createdDate: moment(_it.createdDate).format("l"),
        };
      });
    }
    return [];
  }, [reports]);
  const resetEmail = (email: string) => {
    sendResetEmail(email);
  };
  const resendConfirmEmail = (email: string) => {
    sendConfirmEmail(email);
  };
  const excelSubmittedData = useMemo(() => {
    if (reports?.submittedUsers) {
      return reports.submittedUsers
        .filter(
          (it) =>
            moment(it.letter_send_date).toDate().getFullYear() >=
            moment().toDate().getFullYear()
        )
        .map((_it) => {
          return {
            firstname: _it._Student.firstname,
            lastname: _it._Student.lastName,
            email: _it._Student.email,
            year: _it.year,
            gpa: _it.gpa != null ? gpa_list[_it.gpa].label : "",
            sai: _it.sai,
            pellgrant: _it.pellGrant == 1 ? "Yes" : "No",
            sat: _it.sat_score != null ? satList[_it.sat_score] : "",
            act_score: _it.act_score,
            varsity_athletic: _it.varsity_athletic,
            student_activities: _it.student_activities,
            subsidized_direct_loan: _it.subsidized_direct_loan,
            unsubsidized_direct_loan: _it.unsubsidized_direct_loan,
            intend_major: _it.intend_major,
            createdDate: moment(_it._Student.createdDate).format("l"),
            submittedDate: moment(_it.letter_send_date).format("l"),
          };
        });
    }
    return [];
  }, [reports]);
  const excelnoActivatedData = useMemo(() => {
    if (reports?.noActivatedUsers) {
      return reports.noActivatedUsers.map((_it) => {
        return {
          firstname: _it.firstname,
          lastname: _it.lastName,
          email: _it.email,
          createdDate: moment(_it.createdDate).format("l"),
        };
      });
    }
    return [];
  }, [reports]);
  const excelCompletedData = useMemo(() => {
    if (reports?.full_completed) {
      return reports.full_completed.map((_it) => {
        return {
          firstname: _it.firstname,
          lastname: _it.lastName,
          email: _it.email,
          createdDate: moment(_it.createdDate).format("l"),
        };
      });
    }
    return [];
  }, [reports]);
  const excelnoLoggedInData = useMemo(() => {
    if (reports?.noActivatedUsers) {
      return reports.noLoggedInUsers.map((_it) => {
        return {
          firstname: _it.firstname,
          lastname: _it.lastName,
          email: _it.email,
          createdDate: moment(_it.createdDate).format("l"),
        };
      });
    }
    return [];
  }, [reports]);
  const onManualComplete = (item: Student) => {
    setShowManualCompleteModal(true);
    setCurStudent(item);
  };
  const onResendConfirmEmail = (item: Student) => {
    setShowResendConfirmModal(true);
    setCurStudent(item);
    resendConfirmEmail(item.email);
  };
  return (
    <>
      <Tabs defaultActiveKey="activated">
        <Tab eventKey="activated" title="Not-submitted Users">
          {isLoading || isRefetching ? (
            <LoadingBox />
          ) : (
            <>
              <div className="d-flex justify-content-between mb-1">
                <div>
                  <h6>
                    {" "}
                    Total Not-submitted Users: {totalCountActivatedUsers}
                  </h6>
                  <p>
                    Not Submitted Users = started the process but have not
                    completed it.
                  </p>
                </div>
                <div>
                  <CSVLink
                    data={excelActivateData}
                    filename={`Activated_Users_Report(${moment().format("L")})`}
                    className="btn btn-success fw-bold"
                    target="_blank"
                    onClick={(event, done) => {
                      alert("Exported successfully!");
                    }}
                  >
                    Export Data to CSV
                  </CSVLink>
                </div>
              </div>

              <table className="table table-bordered" ref={activatedRef}>
                <thead>
                  <tr>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                    <th>P_first name</th>
                    <th>P_last name</th>
                    <th>P_email</th>
                    <th>FSS Info</th>
                    <th>Student Info</th>
                    <th>Parent Info</th>
                    <th>Activated Date</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {activateRows.map((it) => (
                    <tr key={it.id}>
                      <td>{it.firstname}</td>
                      <td>{it.lastName}</td>
                      <td>{it.email}</td>
                      {getParent(it.id) ? (
                        <>
                          <td>{getParent(it.id)?.firstName}</td>
                          <td>{getParent(it.id)?.lastName}</td>
                          <td>{getParent(it.id)?.email}</td>
                        </>
                      ) : (
                        <>
                          <td></td>
                          <td></td>
                          <td></td>
                        </>
                      )}
                      <td>{!!getFinancialObj(it.id)?.sai ? "✓" : "✕"}</td>
                      <td>
                        {getFinancialObj(it.id)?.gpa != undefined ? "✓" : "✕"}
                      </td>
                      <td>{!!getParent(it.id) ? "✓" : "✕"}</td>
                      <td>{moment(it.createdDate).format("l")}</td>
                      <td>
                        <Button
                          variant="warning"
                          size={"sm"}
                          onClick={() => onManualComplete(it)}
                        >
                          Manually Completed
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="d-flex">
                <Button
                  variant="secondary"
                  size={"sm"}
                  disabled={activePage == 1}
                  onClick={() => setActivePage(activePage - 1)}
                  className="me-2"
                >
                  Prev
                </Button>
                <Button
                  variant="secondary"
                  size={"sm"}
                  disabled={activateTotalPage == activePage}
                  onClick={() => setActivePage((prev) => ++prev)}
                >
                  Next
                </Button>
              </div>
            </>
          )}
        </Tab>
        <Tab eventKey="submitted" title="Submitted Users">
          {isLoading || isRefetching ? (
            <LoadingBox />
          ) : (
            <>
              <div className="d-flex justify-content-between mb-1">
                <div>
                  <h6>
                    Total Submitted Users:{" "}
                    {
                      reports?.submittedUsers.filter(
                        (it) =>
                          moment(it.letter_send_date).toDate().getFullYear() >=
                          2024
                      ).length
                    }
                  </h6>
                  <p>
                    Submitted Users = completed process, and an email was sent
                    to AMC.
                  </p>
                </div>
                <div>
                  <CSVLink
                    data={excelSubmittedData}
                    filename={`Submitted_Users_Report(${moment().format("L")})`}
                    className="btn btn-success fw-bold"
                    target="_blank"
                    onClick={(event, done) => {
                      alert("Exported successfully!");
                    }}
                  >
                    Export Data to CSV
                  </CSVLink>
                </div>
              </div>
              <table className="table table-bordered" ref={submittedRef}>
                <thead>
                  <tr>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                    <th>Created Date</th>
                    <th>Submitted Date</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {submittedRows.map((it) => (
                    <tr key={it.id}>
                      <td>{it._Student.firstname}</td>
                      <td>{it._Student.lastName}</td>
                      <td>{it._Student.email}</td>
                      <td>{moment(it._Student.createdDate).format("l")}</td>
                      <td>{moment(it.letter_send_date).format("l")}</td>
                      <td className="text-center">
                        <div className="d-flex justify-content-center align-items-center">
                          <Form.Check // prettier-ignore
                            type={"checkbox"}
                            label={`completed`}
                            onClick={() => {
                              setShowCompleteModal(true);
                              setCurStudent(it._Student);
                            }}
                          />
                          <button
                            className="btn btn-success ms-4"
                            onClick={() => {
                              if (it._Student) {
                                setShowLetterModal(true);
                                setCurUserId(it?._Student.id);
                                setCurStudent(it._Student);
                                setCurFinancialAid(it);
                              }
                            }}
                          >
                            Regenerate
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="d-flex">
                <Button
                  variant="secondary"
                  disabled={submittedPage == 1}
                  onClick={() => setSubmittedPage(submittedPage - 1)}
                  size={"sm"}
                  className="me-2"
                >
                  Prev
                </Button>
                <Button
                  variant="secondary"
                  disabled={submittedTotalPage == submittedPage}
                  size={"sm"}
                  onClick={() => setSubmittedPage(submittedPage + 1)}
                >
                  Next
                </Button>
              </div>
            </>
          )}
        </Tab>
        <Tab eventKey="noLoggedIn" title="Not Started Users">
          {isLoading || isRefetching ? (
            <LoadingBox />
          ) : (
            <>
              <div className="d-flex justify-content-between mb-1">
                <div>
                  <h6>
                    Total Not Started Users:{" "}
                    {
                      reports?.noLoggedInUsers.filter((it) => it.isFirst_login)
                        .length
                    }
                  </h6>
                  <p>
                    Not Started Users = Confirmed but have not started the
                    process.
                  </p>
                </div>
                <div>
                  <CSVLink
                    data={excelnoLoggedInData}
                    filename={`Not-Started_Users_Report(${moment().format(
                      "L"
                    )})`}
                    className="btn btn-success fw-bold"
                    target="_blank"
                    onClick={(event, done) => {
                      alert("Exported successfully!");
                    }}
                  >
                    Export Data to CSV
                  </CSVLink>
                </div>
              </div>
              <table className="table table-bordered" ref={activatedRef}>
                <thead>
                  <tr>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                    <th>Created Date</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {reports?.noLoggedInUsers
                    .filter((it) => it.isFirst_login)
                    .map((it) => (
                      <tr key={it.id}>
                        <td>{it.firstname}</td>
                        <td>{it.lastName}</td>
                        <td>{it.email}</td>
                        <td>{moment(it.createdDate).format("l")}</td>
                        <td className="text-center">
                          <Button
                            variant="btn btn-success"
                            size={"sm"}
                            onClick={() => {
                              onResendConfirmEmail(it);
                            }}
                            className="me-2"
                          >
                            Reset Password
                          </Button>
                          <Button
                            variant="warning"
                            size={"sm"}
                            onClick={() => onManualComplete(it)}
                          >
                            Manually Completed
                          </Button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </>
          )}
        </Tab>
        <Tab eventKey="noActivated" title="Not Confirmed Users">
          {isLoading || isRefetching ? (
            <LoadingBox />
          ) : (
            <>
              <div className="d-flex justify-content-between mb-1">
                <div>
                  <h6>
                    Total Not Confirmed Users:{" "}
                    {reports?.noActivatedUsers.length}
                  </h6>
                  <p>
                    Not Confirmed Users = Created an account but have not
                    completed the Password Creation process.
                  </p>
                </div>
                <div>
                  <CSVLink
                    data={excelnoActivatedData}
                    filename={`Not-Comfirmed_Users_Report(${moment().format(
                      "L"
                    )})`}
                    className="btn btn-success fw-bold"
                    target="_blank"
                    onClick={(event, done) => {
                      alert("Exported successfully!");
                    }}
                  >
                    Export Data to CSV
                  </CSVLink>
                </div>
              </div>

              <table className="table table-bordered" ref={activatedRef}>
                <thead>
                  <tr>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                    <th>Created Date</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {reports?.noActivatedUsers.map((it) => (
                    <tr key={it.id}>
                      <td>{it.firstname}</td>
                      <td>{it.lastName}</td>
                      <td>{it.email}</td>
                      <td>{moment(it.createdDate).format("l")}</td>
                      <td className="text-center">
                        <Button
                          variant="btn btn-success"
                          size={"sm"}
                          onClick={() => {
                            onResendConfirmEmail(it);
                          }}
                          className="me-2"
                        >
                          {isSendingConfirmEmail && (
                            <Spinner
                              animation="border"
                              size="sm"
                              className="me-1"
                              variant="light"
                            />
                          )}
                          Resend Confirm Email
                        </Button>
                        <Button
                          variant="warning"
                          size={"sm"}
                          onClick={() => onManualComplete(it)}
                        >
                          Manually Completed
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
        </Tab>
        <Tab eventKey="manual_completed" title="Manually Completed">
          {isLoading || isRefetching ? (
            <LoadingBox />
          ) : (
            <>
              <div className="d-flex justify-content-between mb-1">
                <div>
                  <h6>
                    Total Manually Completed Users:{" "}
                    {reports?.manual_complete_students.length}
                  </h6>
                  {/* <p>
                    Not Confirmed Users = Created an account but have not
                    completed the Password Creation process.
                  </p> */}
                </div>
                <div>
                  <CSVLink
                    data={manualCompletedData}
                    filename={`manual_completed_Users_Report(${moment().format(
                      "L"
                    )})`}
                    className="btn btn-success fw-bold"
                    target="_blank"
                    onClick={(event, done) => {
                      alert("Exported successfully!");
                    }}
                  >
                    Export Data to CSV
                  </CSVLink>
                </div>
              </div>

              <table className="table table-bordered" ref={activatedRef}>
                <thead>
                  <tr>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                    <th>Created Date</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {reports?.manual_complete_students.map((it) => (
                    <tr key={it.id}>
                      <td>{it.firstname}</td>
                      <td>{it.lastName}</td>
                      <td>{it.email}</td>
                      <td>{moment(it.createdDate).format("l")}</td>
                      <td className="text-center">
                        <Button
                          variant="btn btn-success"
                          size={"sm"}
                          onClick={() => {
                            onResendConfirmEmail(it);
                          }}
                          className="me-2"
                        >
                          {isSendingConfirmEmail && (
                            <Spinner
                              animation="border"
                              size="sm"
                              className="me-1"
                              variant="light"
                            />
                          )}
                          Resend Confirm Email
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
        </Tab>
        <Tab eventKey="completed" title="Completed">
          {isLoading || isRefetching ? (
            <LoadingBox />
          ) : (
            <>
              <div className="d-flex justify-content-between mb-1">
                <div>
                  <h6>
                    Total Completed Users: {reports?.full_completed.length}
                  </h6>
                  {/* <p>
                    Not Confirmed Users = Created an account but have not
                    completed the Password Creation process.
                  </p> */}
                </div>
                <div>
                  <CSVLink
                    data={excelCompletedData}
                    filename={`Completed_Users_Report(${moment().format("L")})`}
                    className="btn btn-success fw-bold"
                    target="_blank"
                    onClick={(event, done) => {
                      alert("Exported successfully!");
                    }}
                  >
                    Export Data to CSV
                  </CSVLink>
                </div>
              </div>

              <table className="table table-bordered" ref={activatedRef}>
                <thead>
                  <tr>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                    <th>Created Date</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {reports?.full_completed.map((it) => (
                    <tr key={it.id}>
                      <td>{it.firstname}</td>
                      <td>{it.lastName}</td>
                      <td>{it.email}</td>
                      <td>{moment(it.createdDate).format("l")}</td>
                      <td className="text-center">
                        {/* <Button
                          variant="btn btn-success"
                          size={"sm"}
                          onClick={() => {
                            onResendConfirmEmail(it);
                          }}
                          className="me-2"
                        >
                          {isSendingConfirmEmail && (
                            <Spinner
                              animation="border"
                              size="sm"
                              className="me-1"
                              variant="light"
                            />
                          )}
                          Resend Confirm Email
                        </Button> */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </>
          )}
        </Tab>
      </Tabs>
      {showResendConfirmModal && (
        <ResendConfirmModal
          isSending={isSendingConfirmEmail}
          show={showResendConfirmModal}
          onOk={() => {
            setShowResendConfirmModal(false);
            reloadReports();
          }}
          user={curStudent}
        />
      )}
      {showManualCompleteModal && (
        <ManualCompleteModal
          show={showManualCompleteModal}
          user={curStudent}
          onOk={() => {
            setShowManualCompleteModal(false);
            reloadReports();
          }}
        />
      )}
      {showLetterModal && curUserId && curStudent && curFinancialAid && (
        <RegenerateLetterConfirm
          show={showLetterModal}
          userObj={curStudent}
          onDiscard={() => {
            setShowLetterModal(false);
          }}
          onOk={() => {
            setShowLetterModal(false);
            reloadReports();
          }}
          userId={curUserId}
        />
      )}
      {showResetPasswordModal && (
        <ResetPasswordModal
          isSending={isSendingResetEmail}
          show={showResetPasswordModal}
          onOk={() => {
            setShowResetPasswordModal(false);
            reloadReports();
          }}
          user={curStudent}
        />
      )}
      {showCompleteModal && (
        <TaskCompleteModal
          show={showCompleteModal}
          user={curStudent}
          onOk={() => {
            setShowCompleteModal(false);
            reloadReports();
          }}
        />
      )}
    </>
  );
};
